import * as React from 'react'
import { Helmet } from 'react-helmet'
import { Holding } from '../layouts/Maintenance'
import {
  PageWrapper,
  LayoutTop,
  NoticeWrapper,
  Notice,
  NoticeHeading,
  Button,
  OtherActions,
  LayoutMain,
  Column1,
  Column2,
  Logo,
  MainContent,
  Title,
  DisplayUltra,
  DisplaySmall,
  Video,
  VideoWrapper,
  LayoutBottom,
  CustomerLogo,
  CustomerLogosWrapper,
  CustomerLogosHeading,
  CustomerLogos,
  Address,
  Addresses,
  City,
  StreetAddress,
  CopyrightWrapper
} from '../styles/placeholder.styles'

import LogoImage from '../images/bueno-analytics-logo.svg'
import CustomerLogos1 from '../images/customer-logos-1@2x.png'
import CustomerLogos2 from '../images/customer-logos-2@2x.png'
import CustomerLogos3 from '../images/customer-logos-3@2x.png'
import CustomerLogos4 from '../images/customer-logos-4@2x.png'

// END
const Placeholder = (): JSX.Element => (
  <Holding>
    <Helmet>
      <title>Bueno Systems | Analytics to operate the built environment</title>
    </Helmet>

    <PageWrapper>
      <LayoutTop>
        <NoticeWrapper>
          <Notice>
            <NoticeHeading>
              <strong>We're currently updating our website</strong>
            </NoticeHeading>
            <div>
              <Button style={{ backgroundColor: '#449AEB', cursor: 'pointer' }}>
                <a
                  href="https://mailchi.mp/buenosystems/notifyme"
                  target="_blank"
                  rel="noreferrer"
                >
                  Notify Me
                </a>
              </Button>
              <Button style={{ marginLeft: '8px' }}>
                <a href="mailto:hello@buenosystems.com.au?subject=Enquiry">
                  Email Us
                </a>
              </Button>
            </div>
            <OtherActions>
              <p>Call 1300 130 366</p>
              <a
                href="https://www.linkedin.com/company/buenosystems/"
                target="_blank"
                rel="noreferrer"
              >
                Find us on LinkedIn
              </a>
            </OtherActions>
          </Notice>
        </NoticeWrapper>
      </LayoutTop>

      <LayoutMain>
        <Column1>
          <Logo>
            <img src={LogoImage} height="100%" alt="Bueno Systems" />
          </Logo>

          <MainContent>
            <Title style={{ marginBottom: '8px' }}>Let Data Drive You</Title>
            <DisplayUltra style={{ marginBottom: '16px' }}>
              Unlock operational performance with Bueno
            </DisplayUltra>
            <DisplaySmall style={{ marginBottom: '64px' }}>
              Analytics to operate the built environment
            </DisplaySmall>
            <VideoWrapper>
              <Video>
                <iframe
                  src="https://player.vimeo.com/video/342895999"
                  height="360"
                  width="640"
                  frameBorder="0"
                  allow="autoplay; fullscreen"
                  allowFullScreen
                />
              </Video>
            </VideoWrapper>
          </MainContent>
        </Column1>
        <Column2 />
      </LayoutMain>

      <LayoutBottom>
        <CustomerLogosWrapper>
          <CustomerLogosHeading>
            <strong>3M+ data points, and counting&hellip;</strong>
          </CustomerLogosHeading>
          <CustomerLogos>
            <CustomerLogo>
              <img src={CustomerLogos1} />
            </CustomerLogo>
            <CustomerLogo>
              <img src={CustomerLogos2} />
            </CustomerLogo>
            <CustomerLogo>
              <img src={CustomerLogos3} />
            </CustomerLogo>
            <CustomerLogo>
              <img src={CustomerLogos4} />
            </CustomerLogo>
          </CustomerLogos>
        </CustomerLogosWrapper>

        <Addresses>
          <Address>
            <City>Melbourne(HQ)</City>
            <StreetAddress>
              353 Exhibition Street
              <br />
              Melbourne VIC 3000
            </StreetAddress>
          </Address>
          <Address>
            <City>Sydney</City>
            <StreetAddress>
              Level 3, 6-8 Underwood Street
              <br />
              Sydney NSW 2000
            </StreetAddress>
          </Address>
          <Address>
            <City>Brisbane</City>
            <StreetAddress>
              Level 11, 388 Queen Street
              <br />
              Brisbane QLD 4000
            </StreetAddress>
          </Address>
          <Address>
            <City>Canberra</City>
            <StreetAddress>
              4/13 Canberra Avenue
              <br />
              Fyshwick ACT 2609
            </StreetAddress>
          </Address>
          <Address>
            <City>Perth</City>
            <StreetAddress>
              24 Century Road
              <br />
              Malaga WA 6090
            </StreetAddress>
          </Address>
        </Addresses>

        <CopyrightWrapper>
          <span>
            &copy; Copyright Built Environment Optimisations Pty Ltd 2019. All
            rights reserved.
          </span>
        </CopyrightWrapper>
      </LayoutBottom>
    </PageWrapper>
  </Holding>
)

export default Placeholder
