import * as React from 'react'
import { Helmet } from 'react-helmet'

export const Holding: React.FC = ({ children }) => {
  return (
    <>
      <Helmet>
        <title>Bueno Systems</title>
        <meta
          name="description"
          content="Analytics to operate the built environment."
        />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <link rel="stylesheet" href="https://use.typekit.net/rhh0bpi.css" />
      </Helmet>
      {children}
    </>
  )
}
