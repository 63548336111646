import styled from 'styled-components'
import HeroImage from '../images/img-holding-page.jpg'
import CircleWhiteImage from '../images/img-circle-white.svg'

// TYPOGRAPHY
export const DisplayUltra = styled.h1`
  font-family: neue-haas-grotesk-display, sans-serif;
  font-weight: 900;
  font-size: 3.3rem;
  line-height: 1;
  color: ${props => props.theme.typography.color.ink};

  @media (min-width: ${props => props.theme.breakpoint.desktop}) {
    font-size: 5rem;
  }
`

export const DisplaySmall = styled.h2`
  font-family: neue-haas-grotesk-display, sans-serif;
  font-weight: 700;
  font-size: 1.1875rem;
  line-height: 1.26;
  color: ${props => props.theme.color.highlight};

  @media (min-width: ${props => props.theme.breakpoint.desktop}) {
    font-weight: 700;
    font-size: 1.3125rem;
    line-height: 1.625;
  }
`

export const Title = styled.h3`
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1;
  text-transform: uppercase;

  @media (min-width: ${props => props.theme.breakpoint.desktop}) {
    font-size: 1rem;
  }
`
// END

// LAYOUT
export const PageWrapper = styled.div`
  min-height: 100vh;
  font-family: neue-haas-grotesk-text, sans-serif;
  font-style: normal;
  font-weight: 400;
  display: flex;
  flex-direction: column;
`

export const LayoutTop = styled.div``

export const LayoutMain = styled.div`
  flex-grow: 1;
  display: grid;
  grid-template-rows: auto auto;

  @media (min-width: ${props => props.theme.breakpoint.desktop}) {
    grid-template-rows: none;
    grid-template-columns: 1fr 1fr;
  }
`

export const LayoutBottom = styled.div``
// END

// UI PATTERNS
export const NoticeWrapper = styled.div`
  padding: ${props => props.theme.spacing.default}
    ${props => props.theme.spacing.tight};
  background-color: #fdf2ce;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
  z-index: 1;
  position: relative;

  @media (min-width: ${props => props.theme.breakpoint.desktop}) {
    padding: ${props => props.theme.spacing.snug};
  }
`

export const Notice = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > * {
    margin-bottom: ${props => props.theme.spacing.tight};
  }

  @media (min-width: ${props => props.theme.breakpoint.desktop}) {
    flex-direction: row;

    > * {
      margin-bottom: 0;
      margin-left: ${props => props.theme.spacing.default};
    }
  }
`

export const NoticeHeading = styled.h3`
  font-weight: 700;
  font-size: 1rem;
  line-height: 1;
  color: ${props => props.theme.palette.yellow8};
`

export const OtherActions = styled.div`
  font-size: 0.9375rem;
  color: ${props => props.theme.palette.yellow8};
`

export const Column1 = styled.div`
  position: relative;
  padding: ${props => props.theme.spacing.loose};
  background: ${props => props.theme.palette.neutral1};
  background-image: url(${CircleWhiteImage});
  background-size: 70%;
  background-position: 0 50%;
  background-repeat: no-repeat;

  @media (min-width: ${props => props.theme.breakpoint.desktop}) {
    padding: ${props => props.theme.spacing.ultraLoose};
  }
`

export const Column2 = styled.div`
  background-image: url(${HeroImage});
  background-color: #fff;
  background-size: contain;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  min-height: 400px;
`

export const Logo = styled.div`
  height: 3.75rem;
  margin-bottom: ${props => props.theme.spacing.extraLoose};
  display: inline-block;

  img {
    max-height: 100%;
    margin: 0 auto;
  }

  @media (min-width: ${props => props.theme.breakpoint.desktop}) {
    height: 4.5rem;
    margin-bottom: ${props => props.theme.spacing.ultraLoose};
  }
`

export const Button = styled.span`
  height: 32px;
  display: inline-flex;
  padding: 0 ${props => props.theme.spacing.snug};
  font-weight: 700;
  font-size: 0.875rem;
  align-items: center;
  justify-content: center;
  line-height: 1;
  color: #fff;
  background-color: ${props => props.theme.palette.blue6};
  border-radius: 4px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);

  a {
    height: 32px;
    line-height: 32px;
    display: block;
    color: inherit;
    text-decoration: none;
  }
`

export const MainContent = styled.main``

export const VideoWrapper = styled.div`
  max-width: 48rem;
`

export const Video = styled.div`
  position: relative;
  overflow: hidden;
  padding-top: 56.25%;
  background-color: #211f21;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
`

export const CustomerLogosWrapper = styled.div`
  border-top: 1px solid ${props => props.theme.palette.neutral4};
  padding: ${props => props.theme.spacing.loose};

  @media (min-width: ${props => props.theme.breakpoint.desktop}) {
    padding: ${props => props.theme.spacing.ultraLoose};
  }
`

export const CustomerLogosHeading = styled(DisplaySmall)`
  color: ${props => props.theme.typography.color.ink};
  margin-bottom: ${props => props.theme.spacing.tight};
`

export const CustomerLogos = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-gap: ${props => props.theme.spacing.tight};
  background-color: ${props => props.theme.palette.neutral1};

  @media (min-width: ${props => props.theme.breakpoint.desktop}) {
    grid-template-rows: none;
    grid-template-columns: repeat(4, auto);
    grid-gap: 4%;
  }
`

export const CustomerLogo = styled.div`
  display: flex;
  flex-direction: column;

  img {
    max-width: 100%;
    height: auto;
    align-self: flex-end;
  }

  @media (min-width: ${props => props.theme.breakpoint.desktop}) {
    align-content: flex-start;
    flex-direction: row;
  }
`

export const Addresses = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: ${props => props.theme.spacing.tight};
  padding: ${props => props.theme.spacing.loose};
  background-color: ${props => props.theme.palette.neutral2};
  border-top: 1px solid ${props => props.theme.palette.neutral4};

  @media (min-width: ${props => props.theme.breakpoint.desktop}) {
    padding: ${props => props.theme.spacing.ultraLoose};
    grid-template-columns: repeat(5, 1fr);
    grid-gap: ${props => props.theme.spacing.loose};
  }
`

export const Address = styled.div`
  display: inline-flex;
  flex-direction: column;
  font-size: 0.875rem;
  line-height: 1.25;

  @media (min-width: ${props => props.theme.breakpoint.desktop}) {
    display: inline-flex;
  }
`

export const City = styled.strong`
  font-weight: ${props => props.theme.typography.weight.bold};
`

export const StreetAddress = styled.p`
  font-weight: ${props => props.theme.typography.weight.regular};
`

export const CopyrightWrapper = styled.div`
  color: ${props => props.theme.typography.color.subdued};
  font-size: 0.75rem;
  line-height: 1.2;
  padding: ${props => props.theme.spacing.loose};
  text-align: center;
  background-color: ${props => props.theme.palette.neutral2};
  border-top: 1px solid ${props => props.theme.palette.neutral4};
`
